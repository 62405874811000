.new-header {
    :global {

        .navbar {
            background-color: #F1F4F7 !important;
            margin: 0px !important;
        }

        .nav-link {
            font-family: 'Work Sans';
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-transform: uppercase;
            color: #4382BB;
            margin-left: 45px;

            .bell-icon {
                margin-left: 10px;
            }
        }

        .active {
            background: #579CD4;
            border-radius: 6px;
            color: white !important;
        }

        .profile-pic {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-left: 45px;
            object-fit: cover;
        }

        .MuiAutocomplete-root {
            margin-left: 50px;
            background: #FFFFFF;
            border-radius: 7px;
            border: 0;
        }

        legend {
            display: none;
        }

        .MuiInputBase-input {
            padding: 0px !important;
            padding-left: 25px !important;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 0 !important;
        }

        .MuiAutocomplete-root {
            min-width: 300px;
            max-width: 300px;
            background-image: url("../../../img/common/search.png");
            background-repeat: no-repeat;
            background-position: 10px;
        }

        .drop-down-btn {
            background: transparent;
            border: 0;
        }
    }
}