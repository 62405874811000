.bg-white {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    border-radius: 33px;
    margin: auto;
    padding: 66px 0 85px;
    max-width: 39%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.inside-box {
    max-width: 80%;
    margin: auto;
}

.update-pwd.footer-fixed {
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
}
@media screen and (max-width:1024px) {
    .bg-white {
        background-size: 100% !important;
        width: 62%;
        max-width: 62% !important;
    }
}
@media screen and (max-width:575px) {
    .bg-white {
        background-size: 100% !important;
        width: 90%;
        max-width: 100% !important;
    }
}