* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Work Sans' !important;
}

.container {
  max-width: 1550px !important;
  width: 100% !important;
  padding: 0 15px !important;
  display: table !important;
}

body {
  overflow: initial !important;
  padding-right: 0 !important;
}

.MuiSelect-iconOpen {
  overflow: initial !important;
  padding: 0px !important;
}

.bg-salmon {
  background-color: #f1f4f7;
}

.profile-word-count {
  display: flex;
  justify-content: end;
  align-items: baseline;
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
}

.profile-word-count .count {
  font-size: 12px;
  font-weight: 500;
  padding: 0 3px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

.mt-6 {
  margin-top: 6px;
}

.mt-17 {
  margin-top: 17px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.my-20-26 {
  margin-top: 20px;
  margin-bottom: 26px;
}

.mt-11 {
  margin-top: 11px;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-64 {
  margin-top: 64px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-140 {
  margin-top: 140px;
}

.pt-140 {
  padding-top: 140px;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.ml-33 {
  margin-left: 33px;
}

.pt-200 {
  padding-top: 200px;
  padding-bottom: 100px;
}

.pt-199 {
  padding-top: 200px;
}

.pb-20 {
  padding-bottom: 20px;
}

.text-500 {
  font-weight: 500 !important;
}

.px-60 {
  padding: 0 60px;
}

.px-30 {
  padding: 0 30px;
}

.py-42 {
  padding: 42px 0;
}

.pt-50 {
  padding-top: 50px;
}

.pt-13 {
  padding-top: 13px;
}

dialog {
  color: #F1F4F7;
  ;
}

.TermsOfUse {
  font-family: sans-serif;
  text-align: center;
}

details {
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: .5em .5em 0;
}

summary {
  font-weight: bold;
  margin: -.5em -.5em 0;
  padding: .5em;
}

details[open] {
  padding: .5em;
}

details[open] summary {
  border-bottom: 1px solid #aaa;
  margin-bottom: .5em;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bg-Color-add {
  max-width: 75px;
  min-width: 75px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #1FAD83;
  background-color: #D2EFE6;
}

.bg-Color-remove {
  max-width: 75px;
  min-width: 75px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #C80000;
  background-color: #F5CCCC;
}

.bg-Color-success {
  max-width: 110px;
  min-width: 110px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #1FAD83;
  background-color: #D2EFE6;
}

.bg-Color-info {
  max-width: 110px;
  min-width: 110px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #034ADC;
  background-color: #B3CCFF;
}

.bg-Color-warning {
  max-width: 110px;
  min-width: 110px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #EA9601;
  background-color: #FBEACC;
}

.bg-Color-error {
  max-width: 110px;
  min-width: 110px;
  padding: 5px 10px;
  border-radius: 5px;
  color: #C80000;
  background-color: #F5CCCC;
}

.add-with-serach-box {
  display: flex;
  justify-content: flex-end;
  min-width: 50px;
}

.small-search-icon {
  cursor: pointer;
  max-width: 20px !important;
}

.commom-sky-button {
  background: #5A9BD5;
  box-shadow: 0px 4px 4px rgba(149, 149, 149, 0.25);
  border-radius: 6px;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 0;
}

.commom-blue-button {
  background: #233966;
  box-shadow: 0px 4px 4px rgba(149, 149, 149, 0.25);
  border-radius: 6px;
  padding: 10px 20px;
  color: white !important;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  border: 0;
}

.commom-blue-button a {
  color: white;
}

.commom-blue-button a:hover {
  color: white;
}

.commom-sky-button:hover {
  background: #233966;
}

.commom-blue-button:hover {
  background: #5A9BD5;
}

.font-16 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #233966;
}

.font-18 {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
}

.font-20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #666666;
}

.font-24 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
}

.font-32 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
}

.gary-color {
  color: #666666;
}

.blue-color {
  color: #233966;
}

.light-blue-color {
  color: #203668;
}

.section-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  color: #203668;
}

.section-sub-title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 500;
  color: #000000;
}

.common-bg {
  background-color: #f1f4f7;
  min-height: 100vh;
}

.common-title {
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  text-align: center;
  color: #233966;
}

.bg-blue {
  background: #233966;
  border-radius: 6px;
}

.fixed-footer {
  background-color: #F1F4F7 !important;
  position: absolute;
  bottom: 30px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.fixed-footer .mt-17 {
  margin-top: 0px !important;
}

.mb-20 {
  margin-bottom: 20px;
}



.back-button {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #5C9AD4;
  margin: 25px 0;
}

.back-button span {
  margin-left: 8px;
}

.text-justify {
  text-align: justify;
}

/* ======= Radio , input Common Css ======= */

.MuiRadio-root {
  color: #233966 !important;
}

.MuiInputBase-root,
.MuiFormControl-root {
  width: 100% !important;
}

.MuiInputBase-root {
  margin-top: 0px !important;
}


.MuiInputBase-input {
  background-color: transparent !important;
  /* width: 100% !important; */
}

.MuiButtonBase-root.Mui-checked {
  color: #233966 !important;
  /* filter: drop-shadow(0px 0px 4px #3477FF); */
  filter: none;
}

.MuiSvgIcon-fontSizeMedium.css-zohuh-MuiSvgIcon-root {
  background: white !important;
  border-radius: 50% !important;
  border: 4px solid #0055ff !important;
}

.MuiTypography-root {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #233966 !important;
}

.MuiNativeSelect-select {
  border: 1px solid #ced4da !important;
}

.MuiInputBase-root::before,
.MuiInputBase-root::after {
  border-bottom: 0px !important;
  content: "" !important;
}

.MuiPopperUnstyled-root {
  left: 38px !important;
}

/* ======= Label ======= */

.MuiFormControl-root label {
  font-family: 'Work Sans' !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */
  color: #323232;
  letter-spacing: 0px !important;
  position: inherit;
  /* top: 11px; */
  white-space: inherit;
  width: 130%;
}

.text-red {
  font-size: 16px !important;
  line-height: 19px;
  color: #FF0000;
}

.mw-52 {
  min-width: 52px;
  text-align: end;
}

.MuiPagination-root {
  z-index: auto !important;
}

.MuiInputBase-root input[type=file] {
  opacity: 0;
  overflow: hidden;
  visibility: visible;
  cursor: pointer !important;
}

.inputFile {
  background: #F1F4F7;
  border: 0.5px dashed #233966;
  border-radius: 6px;
}

.file_upload {
  position: absolute !important;
  top: 40px !important;
  z-index: 1 !important;
  left: 50% !important;
  transform: translate(-50%, 0%) !important;
  cursor: pointer !important;
}

.file_upload .content {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 15px !important;
  text-align: center !important;
  color: #323232 !important;
  padding-left: 10px;
}

.file_upload .content span {
  color: #349EFF !important;
}

.MuiButtonBase-root.common-btn {
  background: #5A9BD5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;
  text-transform: initial;
  padding: 9px 29px;
}

.MuiButtonBase-root.common-btn:hover {
  background: #233966;
}

.pb-40 {
  padding-bottom: 40px;
}

.z-index-99 {
  z-index: 99;
}

.select-down-icon .MuiSvgIcon-root {
  background-image: url("../src/img/common/arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: 0px 5px !important;
}

.select-down-icon svg path {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.input-disabled {
  filter: blur(1px);
}

.MuiPopperUnstyled-root {
  left: 0 !important;
}

.MuiPopperUnstyled-root:hover button {
  display: none;
}

/* ======= Modal ========= */

/* .MuiBackdrop-root {
  background-color: #E1E1E1 !important;
} */

.MuiBox-root {
  background: #FFFFFF !important;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px !important;
  border: none !important;
}

.modal-modal-title h1 {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 42px !important;
  text-align: center !important;
  color: #233966 !important;
}

.modal-modal-description {
  font-family: 'Work Sans' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  text-align: center !important;
  color: #323232 !important;
}

.inside-modal {
  max-width: 80%;
  margin: auto;
}

.modal-size .MuiBox-root {
  min-width: 673px;
  z-index: 99999;
}

legend {
  display: none !important;
}

.MuiInputBase-input {
  padding: 0px 10px !important;
  /* min-height: 50px; */
}

.MuiOutlinedInput-notchedOutline {
  top: 0 !important;
}

.success-message {
  font-size: 12px;
  padding-top: 5px;
  color: forestgreen;
}

.success {
  width: 100px !important;
}

.model-text-field-box .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 2.4375em !important;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  position: absolute !important;
  top: 0px !important;
}

.required-icon {
  width: 8px;
}

@media screen and (max-width:1336px) {
  .mt-140 {
    margin-top: 100px;
  }

  .pt-140 {
    padding-top: 100px;

  }

  .mt-100 {
    margin-top: 70px;

  }

  .pt-13 {
    padding-top: 0px;
  }
}

@media screen and (max-width:1152px) {
  .section-title {
    font-size: 40px;
    line-height: 45px;
  }

  .section-sub-title {
    font-size: 30px;
    line-height: 35px;
  }

  .font-20 {
    font-size: 18px;
  }

  .font-24 {
    font-size: 22px;
  }

  .mt-64 {
    margin-top: 50px;
  }

  .mt-50 {
    margin-top: 40px !important;
  }


  .mt-140 {
    margin-top: 80px;
  }

  .pt-140 {
    padding-top: 80px;
  }
}

@media screen and (max-width:992px) {

  .mt-64,
  .mt-40 {
    margin-top: 30px;
  }

  .mt-140,
  .mt-100 {
    margin-top: 50px;
  }

  .pt-140 {
    padding-top: 50px;
  }

  .mb-56 {
    margin-bottom: 25px !important;
  }

  .mt-50 {
    margin-top: 30px !important;
  }

  .inside-modal {
    max-width: 100%;
    margin: auto;
  }
}

@media screen and (max-width:767px) {
  .modal-size .MuiBox-root {
    min-width: initial;
    padding: 20px !important;
    max-width: 90%;
  }

  .modal-modal-title h1 {
    font-size: 30px !important;
    line-height: 35px !important;
  }

  .px-60 {
    padding: 0 30px;
  }

}

@media screen and (max-width:575px) {

  .MuiBox-root {
    top: 55% !important;
    width: 80% !important;
  }


  .commom-sky-button,
  .commom-blue-button {
    font-size: 16px;
    line-height: 19px;
  }

  .section-title {
    font-size: 35px;
    line-height: 40px;
  }

  .section-sub-title {
    font-size: 25px;
    line-height: 30px;
  }

  .my-20-26 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .px-60 {
    padding: 0 15px;
  }

  .success-modal {
    width: 100% !important;
  }

  .ml-33 {
    margin-left: 0px;
  }
}

@media screen and (max-width:480px) {

  .modal-modal-title h1 {
    font-size: 24px !important;
    line-height: 30px !important;
  }

  .file_upload .content {
    font-size: 10px !important;
    line-height: 15px !important;
  }

  .py-42 {
    padding: 30px 0;
  }

}

@media screen and (max-width:375px) {

  .commom-sky-button,
  .commom-blue-button {
    font-size: 13px;
    line-height: 17px;
  }

  .font-18 {
    font-size: 16px;
    line-height: 21px;
  }

  .modal-modal-title h1 {
    font-size: 20px !important;
    line-height: 25px !important;
  }
}


.job-update-container {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  position: relative;
}

.job-update-container .job-update-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}

.job-update-container .job-update-wrapper button {
  position: absolute;
  top: 30px;
  right: 30px;
  background-color: transparent;
  display: none;
  height: 1px;
  width: 1px;
  border: 0;
}

.job-update-container .job-update-wrapper .main-title {
  margin: 35px auto 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #233966;
}

.job-update-container .job-update-wrapper .sub-title {
  padding: 10px 70px 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #323232;
}


.job-update-container .job-update-wrapper .detail-content {
  margin: 15px 105px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  color: #233966;
  padding: 10px;
  background: #FFFFFF;
  border: 1px solid #233966;
  box-shadow: 0px 0px 9px rgba(90, 155, 213, 0.5);
  border-radius: 8px;
}


.pop-up-submit-btn {
  justify-content: center !important;
  padding: 10px 25px;
  margin-bottom: 45px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  background: #233966;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
}

.pop-up-create-btn {
  justify-content: center !important;
  padding: 10px 25px;
  margin-bottom: 45px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  background: #5A9BD5;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: 0;
}

.job-update-container .MuiDialogActions-root {
  justify-content: center !important;

}

.done-icon {
  background-color: #233966 !important;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  padding: 22px;
  margin: auto;
}

.log-out-btn {
  padding: 8px 15px 8px 8px;
  background: transparent;
  border: 0;
  background-color: #233966 !important;
  border-radius: 7px;
  color: #FFFFFF;
  font-family: 'Work Sans';
  font-size: 18px;
}

.add-input {
  width: 15px !important;
  height: 15px !important;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

.job-update-wrapper .MuiSvgIcon-fontSizeMedium {
  position: fixed;
  cursor: pointer;
}


/* input[type="number"] {
    appearance:inherit;
    -webkit-appearance: inherit;
  -moz-appearance: inherit;
  appearance: inherit;
} */


.bg-sky-a {
  color: #5A9BD5 !important;
}

.new-mh-height {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #EDEDED #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
}

*::-webkit-scrollbar-track {
  /* background: #ffffff; */
}

*::-webkit-scrollbar-thumb {
  background-color: #EDEDED;
  border-radius: 8px;
  /* border: 4px solid #ffffff; */
}


/* // ====== Table scroll End  ===== */