.candidate-details {
    padding-bottom: 50px;
    background-color: #F1F4F7;

    // max-height: 650px;
    max-height: calc(100vh - 40px);
    overflow-y: scroll;

    :global {
        .candidate-bg {
            background-color: #FFFFFF;
            box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.15);
            border-radius: 30px;
            padding: 20px 0px;
        }

        .left-side-buttons {
            margin: 8px 0;

            .title {
                font-family: 'Work Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #5C9AD4;
            }

            .button-box {
                display: flex;
                justify-content: start;
                place-items: center;
                min-width: 120px;
                cursor: pointer;

                .active-status {
                    font-family: 'Work Sans';
                    font-weight: 500;
                    line-height: 16px;
                    color: #1FAD83;
                    padding: 4px 8px;
                    background: #D2EFE6;
                    border-radius: 3px;
                    width: max-content;
                }

                .requested-status {
                    font-family: 'Work Sans';
                    font-weight: 500;
                    line-height: 16px;
                    color: #EA9601;
                    padding: 4px 8px;
                    background: #FBEACC;
                    border-radius: 3px;
                    width: max-content;

                }

                .closed-status {
                    font-family: 'Work Sans';
                    font-weight: 500;
                    line-height: 16px;
                    color: #C80000;
                    padding: 4px 8px;
                    background: #F5CCCC;
                    border-radius: 3px;
                    width: max-content;

                }

                .request-status {
                    font-family: 'Work Sans';
                    font-weight: 500;
                    line-height: 16px;
                    color: #034ADC;
                    padding: 4px 8px;
                    background: #B3CCFF;
                    border-radius: 3px;
                    width: max-content;

                }
            }

        }

        .question-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: #233966;
        }

        .border-btm {
            border-bottom: 1px solid #DEDEDE;
            padding-top: 11px;
        }

        .questions {
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #A4A4A4;
        }

        .answers {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #323232;
            margin: 5px 0;


            .bg-Color-success {
                max-width: 70px;
                min-width: 70px;
                padding: 5px 10px;
                margin: 5px 0;
                border-radius: 5px;
                color: #1FAD83;
                background-color: #D2EFE6;
            }

            .bg-Color-info {
                max-width: 120px;
                min-width: 120px;
                padding: 5px 10px;
                margin: 5px 0;
                border-radius: 5px;
                color: #034ADC;
                background-color: #B3CCFF;
            }

            .bg-Color-warning {
                max-width: 80px;
                min-width: 80px;
                padding: 5px 10px;
                margin: 5px 0;
                border-radius: 5px;
                color: #EA9601;
                background-color: #FBEACC;
            }

            .bg-Color-error {
                max-width: 75px;
                min-width: 75px;
                padding: 5px 10px;
                margin: 5px 0;
                border-radius: 5px;
                color: #C80000;
                background-color: #F5CCCC;
            }

        }

        .answer-bg {
            background: #E5EEF7;
            border-radius: 5px;
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #1C3962;
            // width: max-content;
            width: fit-content;
            padding: 4px 8px;
            margin: 5px 0;
        }

        .answer-bg-active {
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #1FAD83;
            background: #D2EFE6;
            border-radius: 5px;
            width: max-content;
            padding: 4px 8px;
            margin: 5px 0;
        }

        .bg-sky {
            background: rgba(215, 231, 245, 0.5);
            padding: 6px 0;
        }

        .bg-sky h3 {
            font-family: 'Work Sans';
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #579CD4;
        }

    }
}