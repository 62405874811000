.sign-up {
    :global {
        .signup-bg {
            background-image: url("../../img/Signup/sign-up-bg.png");
            // background-size: cover;
            background-repeat: no-repeat;
            min-height: 100vh;
            background-size: contain;
        }

        .signup-box {
            max-width: 80%;
            margin: auto;
            background: #FFFFFF;
            box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.1);
            border-radius: 30px;
            padding: 33px 64px 44px 64px;

            .single-auto-compelete-width .MuiOutlinedInput-root {
                padding: 0;
                max-height: 44px !important;
            }
            
        }

        .sign-up-icon {
            padding: 27px 0 40px;
        }

        .MuiInputBase-input {
            padding: 0px 10px !important;
            min-height: 50px;
        }

        .MuiOutlinedInput-notchedOutline {
            top: 0 !important;
        }

  

        .checkBox {
            a {
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0px !important;
                position: inherit;
                white-space: inherit;
            }
        }
        .MuiButtonBase-root.Mui-checked {
            color: #233966 !important;
            filter:none;
          }
          .MuiFormControl-root label {
            width: 100% !important;
        }
    }
}


@media screen and (max-width:575px) {
    .sign-up {
        :global {
            .signup-box {
                max-width: 100% !important;
                padding: 33px 10px !important;
            }
        }
    }
}