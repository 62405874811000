footer ul li a,
.footer-content {
    color: #282828 !important;
}

footer ul li {
    padding-top: 9px;
}

.footer-border {
    border-bottom: 1px solid #B1B1B1;
}

.social-bg {
    width: 35px;
    height: 35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    position: relative;
}

.social-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}